@font-face {
  font-family: "Lato Regular";
  src: url("../assets/fonts/lato/lato-regular-webfont.eot");
  src: url("../assets/fonts/lato/lato-regular-webfont.eot") format("embedded-opentype"),
  url("../assets/fonts/lato/lato-regular-webfont.woff2") format("woff2"),
  url("../assets/fonts/lato/lato-regular-webfont.woff") format("woff"),
  url("../assets/fonts/lato/lato-regular-webfont.ttf") format("truetype"),
}
@font-face {
  font-family: "Lato Italic";
  src: url("../assets/fonts/lato/lato-italic-webfont.eot");
  src: url("../assets/fonts/lato/lato-italic-webfont.eot") format("embedded-opentype"),
  url("../assets/fonts/lato/lato-italic-webfont.woff2") format("woff2"),
  url("../assets/fonts/lato/lato-italic-webfont.woff") format("woff"),
  url("../assets/fonts/lato/lato-italic-webfont.ttf") format("truetype"),
}
@font-face {
  font-family: "Lato Bold";
  src: url("../assets/fonts/lato/lato-bold-webfont.eot");
  src: url("../assets/fonts/lato/lato-bold-webfont.eot") format("embedded-opentype"),
  url("../assets/fonts/lato/lato-bold-webfont.woff2") format("woff2"),
  url("../assets/fonts/lato/lato-bold-webfont.woff") format("woff"),
  url("../assets/fonts/lato/lato-bold-webfont.ttf") format("truetype"),
}
